import React from "react";
import BlogPostPage from "../components/BlogPostPage";
import MainLayout from "../layout/mainLayout";

const BlogPostPpage = () => {
  return (
    <MainLayout>
      <BlogPostPage />
    </MainLayout>
  );
};

export default BlogPostPpage;

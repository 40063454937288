import React from "react";
import FindGameMaster from "../components/FindGameMaster";
import MainLayout from "../layout/mainLayout";

const FindGM = () => {
  return (
    <MainLayout>
      <FindGameMaster />
    </MainLayout>
  );
};

export default FindGM;

import React from "react";
import MyGame from "../components/MyGame";
import MainLayout from "../layout/mainLayout";

const MYGamePage = () => {
  return (
    <MainLayout>
      <MyGame />
    </MainLayout>
  );
};

export default MYGamePage;

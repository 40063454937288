import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { confirmIntentApi } from "../../../network/api/otherDetailsApi";

import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";

const SetupForm = ({ setLoading }) => {
  const { id } = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState();
  // const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    message.error(error?.message);
    setErrorMessage(error?.message);
  };

  const handleServerResponse = async (response) => {
    response?.message && message.error(response.message);
    if (response.error) {
      setLoading(false);
    } else if (response.status === "requires_action") {
      // Use Stripe.js to handle the required next action
      const { error } = await stripe.handleNextAction({
        clientSecret: response.client_secret,
      });

      if (error) {
        // Show error from Stripe.js in payment form
      } else {
        setLoading(true);
      }
    } else if (response.status === "succeeded") {
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    try {
      event.preventDefault();
      if (!stripe) {
        return;
      }
      const { error: submitError } = await elements.submit();
      if (submitError) {
        handleError(submitError);
        return;
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        // params: {
        //   billing_details: {
        //     name: "Jenny Rosen",
        //   },
        // },
      });

      if (error) {
        handleError(error);
        return;
      }

      if (paymentMethod?.id) {
        const res = await confirmIntentApi({
          payment_method_id: paymentMethod.id,
          game_id: id,
        });
        if (!res) {
          setLoading(false);
        }
        if (res?.data?.status_code === 200 || res?.data?.status_code === 201) {
          handleServerResponse(res?.data?.data);
        }
      }
    } catch (error) {
      setLoading(false);
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="paying-btn-wrap">
        <button
          type="submit"
          disabled={!stripe}
          className="see-all-btn"
          style={{
            fontSize: "1rem",
            height: "2.5rem",
            fontWeight: "500",
            lineHeight: "1.25rem",
            borderRadius: "3.125rem",
            minWidth: "10.625rem",
            backgroundColor: "#ffb100",
            borderColor: "#ffb100",
            color: "#fff",
            boxShadow: "none",
            cursor: "pointer",
            border: "none",
          }}
        >
          Submit
        </button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;

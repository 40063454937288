import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Button, Image, Row, Col, Space } from "antd";

import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  CheckOutlined,
  ReadOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./HomePage.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { homeApi } from "../../network/api/otherDetailsApi";
import GameComp from "./gameComp";
import GmComp from "./gmComp";
const { Title, Paragraph } = Typography;

const HomePage = () => {
  const navigate = useNavigate();
  let { authenticate } = useSelector((state) => ({
    authenticate: state.authUser?.authenticate,
    stripeData: state.stripeData.key,
  }));

  const [games, setGames] = useState([]);
  const [gameMasters, setGameMasters] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getHomeData = async () => {
    try {
      let data = await homeApi();
      if (data) {
        setGames(data?.games);
        setGameMasters(data?.game_masters);
        setBlogs(data?.blogs);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getHomeData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="banner">
        <div className="container">
          <Title className="text-left" level={1}>
            TuGameMaster Dungeons & Dragons
          </Title>
          <Paragraph className="banner-text">
            {/* Tabletop RPGs Run by Professional Game Masters */}
            Tu Game Master
          </Paragraph>
          <Space wrap className="banner-btn-wrap">
            <Button
              className="primary-btn"
              size="large"
              type="primary"
              onClick={() => navigate("/games")}
            >
              Find Games
            </Button>
            <Button
              className="primary-btn"
              size="large"
              type="primary"
              onClick={() => navigate("/game-masters")}
            >
              Find GMs
            </Button>
            <Button
              className="primary-btn"
              size="large"
              type="primary"
              onClick={() => navigate("/blogs")}
            >
              Blogs
            </Button>
            <Button
              className="primary-btn"
              size="large"
              type="primary"
              onClick={() => {
                if (authenticate) {
                  navigate("/game-master");
                } else {
                  navigate("/signup");
                }
              }}
            >
              Become a GM
            </Button>
          </Space>
        </div>
      </div>

      <div className="steps-wrap">
        <div className="container">
          <Title className="secondary-heading text-left" level={4}>
            Steps for playing game with GM
          </Title>
          <Row gutter={[20, 20]} className="step-box">
            <Col xs={24} sm={12}>
              <div className="benefit-box">
                <SearchOutlined />
                <Title level={4}>First Step</Title>
                <Paragraph className="text">
                  First, find a game that you like and suits your schedule.
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="benefit-box">
                <CheckOutlined />
                <Title level={4}>Second Step</Title>
                <Paragraph className="text">
                  Then wait for the GM to confirm that game.
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="benefit-box">
                <ReadOutlined />
                <Title level={4}>Third Step</Title>
                <Paragraph className="text">
                  Get ready and read carefully the instructions given by the GM.
                </Paragraph>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="benefit-box">
                <PlayCircleOutlined />
                <Title level={4}>Fourth Steps</Title>
                <Paragraph className="text">
                  Play the game and let us know how was the adventure.
                </Paragraph>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="upcoming-games">
        <div className="container position-relative mx-1300">
          <Title className="secondary-heading text-left" level={2}>
            Upcoming games
          </Title>
          <Slider className="upcoming-carousel" {...settings}>
            {games.length > 0 &&
              games?.map(
                (game) =>
                  game && (
                    <GameComp game={game} key={`homePageGame-${game.id}`} />
                  )
              )}
          </Slider>
        </div>
        <Button
          className="see-all-btn"
          type="primary"
          size="large"
          onClick={() => {
            navigate("/games");
          }}
        >
          See All Games
        </Button>
      </div>

      <div className="team-wrap">
        <div className="container">
          <Title className="secondary-heading text-left" level={4}>
            Check out our Game Masters
          </Title>
          <Row gutter={[30, 30]}>
            {gameMasters.length > 0 &&
              gameMasters?.map(
                (gm) =>
                  gm && (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      key={`homePageGm-${gm.id}`}
                    >
                      <GmComp gm={gm} />
                    </Col>
                  )
              )}
          </Row>
          <Button
            className="see-all-btn"
            type="primary"
            size="large"
            onClick={() => {
              navigate("/game-masters");
            }}
          >
            See All GM's
          </Button>
        </div>
      </div>

      <div className="blog-post-wrap">
        <div className="container">
          <Title className="secondary-heading text-left" level={4}>
            See Our Blogs
          </Title>
          <div className="blog-post-card-wrap">
            {blogs?.length > 0 &&
              blogs?.map((blog) => {
                return (
                  <div
                    key={`homePageBlog-${blog.id}`}
                    className="blog-post-card"
                    onClick={() => {
                      navigate(`/blogs/${blog?.id}`);
                    }}
                  >
                    <Image preview={false} src={blog.image || "/dragon.webp"} />
                    <div className="content">
                      <Title level={5}>{blog?.title || "Blog Post"}</Title>
                    </div>
                  </div>
                );
              })}
          </div>
          <Button
            className="see-all-btn"
            type="primary"
            size="large"
            onClick={() => {
              navigate("/blogs");
            }}
          >
            See All Blogs
          </Button>
        </div>
      </div>

      <div className="container">
        <div className="become-wrap">
          <div className="become-inr">
            <Title className="secondary-heading" level={2}>
              Become a Game Master
            </Title>
            <Paragraph className="text">
              Join 100's of other Professional Game Masters
            </Paragraph>
            <Link to="/signup" type="primary" size="large">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;

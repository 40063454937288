import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

export const snakeToCamel = (str) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );
};
export const camelCaseKeys = (obj) =>
  Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [snakeToCamel(field)]: obj[field],
    }),
    {}
  );

export const setCookie = function (cname, cvalue, expiresIn) {
  const d = new Date();
  d.setTime(d.getTime() + expiresIn * 1000);

  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = function (cname) {
  if (typeof document === "undefined") return "";

  const ca = document.cookie.split(";");

  const token = ca.find((c) => {
    return c.replace(" ", "").split("=")[0] === cname;
  });

  if (!token) return "";

  return token.split("=")[1];
};

export const timeConvertToDateTime = (date) => {
  dayjs.extend(utc);
  let parsedDate = dayjs(date);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let formattedTime = parsedDate.format("YYYY-MM-DD hh:mm A");
  return `${formattedTime} (${timeZone})`;
};

export const timeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

export const localToUtc = (date) => {
  dayjs.extend(utc);
  let parsedDate = dayjs(date);
  let utcDate = parsedDate.utc();
  let formattedTime = utcDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  return formattedTime;
};

import React from "react";
import MyPaymentsPage from "../components/MyPaymentsPage";
import MainLayout from "../layout/mainLayout";

const MyPayments = () => {
  return (
    <MainLayout>
      <MyPaymentsPage />
    </MainLayout>
  );
};

export default MyPayments;

import React from "react";
import ChangePassword from "../components/Settings/changePassword";
import MainLayout from "../layout/mainLayout";

const Protection = () => {
  return (
    <MainLayout>
      <ChangePassword />
    </MainLayout>
  );
};

export default Protection;

import React from "react";
import PayoutStartOver from "../components/Settings/payoutsStartOver";
import MainLayout from "../layout/mainLayout";

const PayoutOverPage = () => {
  return (
    <MainLayout>
      <PayoutStartOver />
    </MainLayout>
  );
};

export default PayoutOverPage;

import { Outlet, useNavigate } from "react-router-dom";
import { getCookie } from "../utils/utils";
import React, { useEffect } from "react";

const Protected = () => {
  const navigate = useNavigate();
  const accessToken = getCookie("accessToken");

  useEffect(() => {
    if (!accessToken) {
      navigate("/login"); // Navigate to the login page
    }
  }, [accessToken, navigate]);

  // Only render the Outlet if the accessToken exists
  return accessToken ? <Outlet /> : null;

  // const getTokenInitial = async () => {
  //   const refreshToken = getCookie("refreshToken");
  //   try {
  //     if (!refreshToken) {
  //       dispatch(setSignOut());
  //       document.cookie =
  //         "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       document.cookie =
  //         "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       navigate("/login");
  //     } else if (refreshToken) {
  //       let res = await getRefresfTokenApi();
  //       if (res?.status != 200) {
  //         dispatch(setSignOut());
  //         document.cookie =
  //           "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         document.cookie =
  //           "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         navigate("/login");
  //       }
  //       return <Outlet />;
  //     }
  //   } catch (error) {
  //     navigate("/login");
  //   }
  // };

  //   try {
  //     if (!refreshToken) {
  //       dispatch(setSignOut());
  //       document.cookie =
  //         "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       document.cookie =
  //         "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       navigate("/login");
  //     } else if (refreshToken) {
  //       let res = await getRefresfTokenApi();
  //       if (res?.status != 200) {
  //         dispatch(setSignOut());
  //         document.cookie =
  //           "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         document.cookie =
  //           "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //         navigate("/login");
  //       }
  //       return <Outlet />;
  //     }
  //   } catch (error) {
  //     navigate("/login");
  //   }
  // };

  // useEffect(() => {
  //   getTokenInitial();
  // }, []);

  // return <Outlet />;
};

export default Protected;

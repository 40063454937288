import NetworkConstants from "./networkConstants";

const K = {
  Network: NetworkConstants,
};

export default K;

export const gameTypeOptions = [
  {
    value: "one_shot",
    label: "One Shot",
  },
];

export const requiredExperienceOptions = [
  {
    value: "not_required",
    label: "Not Required",
  },
  {
    value: "beginner",
    label: "Beginner",
  },
  {
    value: "experienced",
    label: "Experienced",
  },
  {
    value: "expert",
    label: "Expert",
  },
];

export const characterCreationOptions = [
  {
    value: "created_in_game",
    label: "Created In Game",
  },
  {
    value: "created_by_players_beforehand",
    label: "Created By Players Beforehand",
  },
  {
    value: "created_by_game_master_beforehand",
    label: "Created By Game Master Beforehand",
  },
];

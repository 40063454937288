import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Form,
  Input,
  Button,
  Image,
  Select,
  Spin,
  Row,
  Col,
} from "antd";
import { convert } from "html-to-text";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getFindGMPublicApi,
  lauguagesApi,
} from "../../network/api/otherDetailsApi";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

const FindGameMaster = () => {
  const [loading, setLoading] = useState(false);
  const [apply, setApply] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);

  const navigate = useNavigate();
  const [language, setLanguage] = useState("");
  const [gmList, setGmList] = useState([]);
  const [search, setSearch] = useState("");

  let { languages } = useSelector((state) => ({
    languages: state.otherDetails.languages,
  }));

  const ListApiCalls = async () => {
    try {
      setLoading(true);
      const games = await getFindGMPublicApi({ offset, language, search });
      if (games?.status_code === 200) {
        setGmList([...gmList, ...games.data.results]);
        if (games?.data?.count > offset + 20) {
          setOffset(offset + 20);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        await lauguagesApi();
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    ListApiCalls();
  }, [apply]);

  return (
    <>
      <div className="games-wrapper">
        <div className="sub-banner">
          <div className="container">
            <Title level={2}>Find Game Master</Title>
            <Text className="profile-text">
              Play D&D and Other Tabletop Roleplaying Games Online
            </Text>
          </div>
        </div>
        <div className="filter-wrap">
          <Form layout="vertical">
            <Space className="filter-inr" size={16}>
              <Space size={30}>
                <Form.Item label="Search By Name">
                  <Input
                    placeholder="Search GM"
                    value={search}
                    style={{
                      width: 250,
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    size="large"
                  />
                </Form.Item>
                <Form.Item label="Language">
                  <Select
                    value={language}
                    size="large"
                    style={{
                      width: 180,
                    }}
                    showSearch
                    onChange={(lang) => {
                      setLanguage(lang);
                    }}
                  >
                    {languages.length > 0 &&
                      languages.map((language) => (
                        <Select.Option value={language.id} key={language?.id}>
                          {language?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Space>
              <Space className="apply-filters">
                <Button
                  size="large"
                  className="clear-filter"
                  ghost
                  onClick={() => {
                    setGmList([]);
                    setOffset(0);
                    setHasMore(false);
                    setApply(!apply);
                  }}
                >
                  Apply
                </Button>
                <Button
                  className="clear-filter"
                  ghost
                  size="large"
                  onClick={() => {
                    setSearch("");
                    setLanguage("");
                    setApply(!apply);
                  }}
                >
                  Clear Filter
                </Button>
              </Space>
            </Space>
          </Form>
          <div className="container">
            <Spin spinning={loading} size="large">
              <Row style={{ marginTop: 30 }} gutter={[16, 16]}>
                {gmList?.length > 0 &&
                  gmList?.map(
                    (gm) =>
                      gm && (
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          key={`gameMaster-${gm.id}`}
                        >
                          <div
                            className="team-card find-gm-card"
                            onClick={() => {
                              navigate(`/game-master/${gm.id}`);
                            }}
                          >
                            <div>
                              <Image
                                preview={false}
                                width={130}
                                height={130}
                                src={gm?.user?.picture || "/user_profile.png"}
                              />
                            </div>
                            <div className="gm-page-listing">
                              <Title className="card-heading" level={4}>
                                {gm?.name}
                              </Title>
                              <Text className="text">
                                {gm?.total_reviews
                                  ? `(${gm?.average_reviews}) ${gm?.total_reviews} reviews`
                                  : "No reviews "}
                              </Text>
                              <ul className="team-list">
                                <li>
                                  <strong>{gm?.experience_game_master}</strong>{" "}
                                  years as a GM
                                </li>
                                <li>
                                  <strong>{gm?.games_hosted}</strong> Games
                                  Hosted
                                </li>
                              </ul>
                            </div>
                            <div>
                              <p>
                                {convert(gm?.about_me_game_master, {
                                  wordwrap: 130,
                                }).substring(0, 780) + "..."}
                              </p>
                            </div>
                          </div>
                        </Col>
                      )
                  )}
              </Row>
              {hasMore && (
                <Button
                  type="primary"
                  size="large"
                  style={{
                    marginTop: 100,
                  }}
                  onClick={() => {
                    !loading && ListApiCalls();
                  }}
                >
                  Load More {loading && <LoadingOutlined />}
                </Button>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindGameMaster;

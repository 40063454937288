import { store } from "../../store";
import {
  setAuthTokens,
  setGmInfo,
  setLoginUser,
  setUserInfo,
} from "../../store/slices/authUser";
import { getCookie, setCookie } from "../../utils/utils";
import NetworkCall from "../networkCall";
import AuthReqest from "../request/authRequest";
import { message } from "antd";
import axios from "axios";

const ACCESS_TOKEN_EXPIRE_SECONDS = 10;
const REFRESH_TOKEN_EXPIRE_SECONDS = 360;

function getTokenExpiryTime(isAccess = false) {
  const date = new Date();
  date.setTime(
    date.getTime() +
      (isAccess ? ACCESS_TOKEN_EXPIRE_SECONDS : REFRESH_TOKEN_EXPIRE_SECONDS) *
        1000
  );
  return date.toUTCString();
}

export const getMeApiInternal = async () => {
  const token = getCookie("accessToken");
  try {
    let data2 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/users/me/`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    return data2;
  } catch (error) {
    let errmsg = error?.response?.data?.detail;
    errmsg && message.error(errmsg);
    return error;
  }
};

export const loginApi = async (reqBody) => {
  try {
    let data = await NetworkCall.fetch(
      AuthReqest.getLoginRequest(reqBody),
      false
    );

    setCookie("accessToken", data.access_token, data.expires_in);
    setCookie("refreshToken", data.access_token, "");

    let data2 = await getMeApiInternal();
    let gm = data2?.data?.data;
    const { user_game_master, ...modifiedGMObject } = gm;
    let authInfo = {
      id: data2?.data?.data?.id,
      refreshToken: data.refresh_token,
      accessToken: data.access_token,
      user: modifiedGMObject || {},
      gmInfo: data2?.data?.data?.user_game_master,
    };
    store.dispatch(setLoginUser(authInfo));
    return data;
  } catch (error) {
    let errmsg = error?.error?.data?.error_description;
    errmsg && message.error(errmsg);
    return error;
  }
};

export const googleAuthApi = async (reqBody) => {
  try {
    let data = await NetworkCall.fetch(
      AuthReqest.googleAuthRequest(reqBody),
      false
    );

    setCookie("accessToken", data.access_token, data.expires_in);
    setCookie("refreshToken", data.access_token, data.expires_in);

    let data2 = await getMeApiInternal();
    let gm = data2?.data?.data;

    const { user_game_master, ...modifiedGMObject } = gm;
    let authInfo = {
      id: data2?.data?.data?.id,
      refreshToken: data.refresh_token,
      accessToken: data.access_token,
      user: modifiedGMObject || {},
      gmInfo: data2?.data?.data?.user_game_master,
    };
    store.dispatch(setLoginUser(authInfo));
    return data;
  } catch (error) {
    let errmsg = error?.error?.data?.detail;
    errmsg && message.error(errmsg);
    return error;
  }
};

export const signupApi = async (reqBody) => {
  try {
    let data = await NetworkCall.fetch(
      AuthReqest.getSignupRequest(reqBody),
      false
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const changePasswordApi = async (reqBody) => {
  try {
    let data;
    const token = getCookie("accessToken");
    data = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/users/update-password/`,
      reqBody,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    message.success(data?.data?.message);
    return data;
  } catch (error) {
    let errmsg = error?.response?.data?.message;
    errmsg && message.error(errmsg);
    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }
    return null;
  }
};

export const deleteAccountApi = async (reqBody) => {
  try {
    let data;
    const token = getCookie("accessToken");

    data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/users/delete/`,
      reqBody,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }
    return null;
  }
};

export const getRefresfTokenApi = async () => {
  try {
    let data;
    const token = getCookie("refreshToken");

    data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/auth/token`,
      {
        grant_type: "refresh_token",
        refresh_token: token,
        client_secret: "secret",
        client_id: "client",
      },
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    document.cookie = `accessToken=${
      data?.data?.access_token
    }; expires=${getTokenExpiryTime(true)}; path=/`;
    document.cookie = `refreshToken=${
      data?.data.refresh_token
    }; expires=${getTokenExpiryTime()}; path=/`;

    store.dispatch(
      setAuthTokens({
        refreshToken: data?.data.refresh_token,
        accessToken: data?.data.access_token,
      })
    );

    // message.success(data?.data?.message)
    return data;
  } catch (error) {
    let errmsg = error?.response?.data?.error;
    errmsg && message.error(errmsg);

    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }

    return null;
    // message.error(error?.response?.data?.message);
    // return null;
  }
};

export const updateUserProApi = async (reqBody) => {
  try {
    let data;
    let id;
    let state = await store.getState();
    const { authUser } = state;
    const token = getCookie("accessToken");
    id = authUser.user.id;
    data = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/users/${id}/`,
      reqBody,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (data?.data?.status_code === 200) {
      store.dispatch(setUserInfo(data?.data?.data || {}));
      message.success("Profile is updated successfully");
    }
    // message.success(data?.data?.message)
    return data;
  } catch (error) {
    let errmsg = error?.response?.data?.message;
    errmsg && message.error(errmsg);
    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }

    return null;
  }
};

export const getGmMeApi = async () => {
  try {
    let data;
    let id;
    let state = await store.getState();
    const { authUser } = state;
    const token = getCookie("accessToken");
    id = authUser.gmInfo.id;
    data = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/game-masters/${id}/`,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (data?.data?.status_code === 200) {
      store.dispatch(setGmInfo(data?.data?.data || {}));
    }
    return data;
  } catch (error) {
    let errmsg = error?.response?.data?.detail;
    errmsg && message.error(errmsg);
    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }
    return null;
  }
};

export const becomeaGmApi = async () => {
  try {
    let data;
    const token = getCookie("accessToken");
    data = await axios.post(
      `${process.env.REACT_APP_BASE_URL}api/game-masters/become-game-master/`,
      {},
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );

    if (data?.data?.status_code === 400) {
      let errmsg = data?.data?.message;
      errmsg && message.error(errmsg);

      return { status_code: data?.data?.status_code };
    }

    let data2 = await getMeApiInternal();
    let gm = data2?.data?.data;
    const { user_game_master, ...modifiedGMObject } = gm;
    let authInfo = {
      id: data2?.data?.data?.id,
      accessToken: data.access_token,
      user: modifiedGMObject || {},
      gmInfo: data2?.data?.data?.user_game_master,
    };
    store.dispatch(setLoginUser(authInfo));
    message.success(data?.data?.message);
    return { status_code: data?.data?.status_code };
  } catch (error) {
    let errmsg = error?.response?.data?.message;
    errmsg && message.error(errmsg);

    if (error?.response?.status === 401) {
      throw new Error("Unauthorized");
    }
    return null;
  }
};

export const updateGmProApi = async (reqBody) => {
  try {
    let data;
    let id;
    let state = await store.getState();
    const { authUser } = state;
    const token = getCookie("accessToken");
    id = authUser.gmInfo.id;
    data = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}api/game-masters/${id}/`,
      reqBody,
      {
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
    if (data?.data?.status_code === 200) {
      store.dispatch(setGmInfo(data?.data?.data || {}));
      message.success("GM profile is updated successfully");
    }
    return data;
  } catch (error) {
    let errmsg = error?.response?.data?.message;
    errmsg && message.error(errmsg);
    return error?.response;
  }
};

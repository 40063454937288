import React from "react";
import SingleGameMaster from "../components/SingleGameMaster";

import MainLayout from "../layout/mainLayout";

const SingleGmPage = () => {
  return (
    <MainLayout>
      <SingleGameMaster />
    </MainLayout>
  );
};

export default SingleGmPage;

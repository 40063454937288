import React from "react";
import DashBoardItems from "../components/DashBoard";
import MainLayout from "../layout/mainLayout";

const DashBoard = () => {
  return (
    <MainLayout>
      <DashBoardItems />
    </MainLayout>
  );
};

export default DashBoard;

import React from "react";

import MainLayout from "../layout/mainLayout";
import UpdateGameForm from "../components/UpdateGame";

const UpdateGame = () => {
  return (
    <MainLayout>
      <UpdateGameForm />
    </MainLayout>
  );
};

export default UpdateGame;

import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);
const GamePaymentMain = ({ setLoading }) => {
  const options = {
    mode: "setup",
    currency: "eur",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "stripe",
    },
  };
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm setLoading={setLoading} />
        </Elements>
      )}
    </>
  );
};

export default GamePaymentMain;

import React from "react";
import Payouts from "../components/Settings/payouts";
import MainLayout from "../layout/mainLayout";

const PayoutPage = () => {
  return (
    <MainLayout>
      <Payouts />
    </MainLayout>
  );
};

export default PayoutPage;

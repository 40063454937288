import React from "react";

import MainLayout from "../layout/mainLayout";
import SingleBlogPost from "../components/SingleBlogPost";

const SinglePostPage = () => {
  return (
    <MainLayout>
      <SingleBlogPost />
    </MainLayout>
  );
};

export default SinglePostPage;

import React, { useEffect, useState } from "react";
import { Typography, Table, Spin, Modal, message } from "antd";

import MenuSettings from "../Settings/settingsMenu";
import {
  cancelIntentApi,
  getPaymentsListApi,
} from "../../network/api/otherDetailsApi";
import { useNavigate } from "react-router-dom";

import { CloseCircleOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { timeConvertToDateTime } from "../../utils/utils";
const { Link } = Typography;

const columns = [
  // {
  //   title: "",
  //   dataIndex: "gm_avatar",
  //   key: "gm_avatar",
  //   width: "10%",
  // },
  {
    title: "Game Master",
    dataIndex: "gm_email",
    key: "gm_email",
  },
  {
    title: "Game",
    dataIndex: "game_title",
    key: "game_title",
  },

  {
    title: "Client",
    dataIndex: "client",
    key: "client",
  },
  {
    title: "Session Date",
    dataIndex: "session_date",
    key: "session_date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const MyPaymentsPage = () => {
  const [loading, setLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loadOffset, setLoadOffset] = useState(0);
  const [cancelId, setCancelId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async (e) => {
    try {
      if (cancelId) {
        setLoading(true);
        let data = await cancelIntentApi(cancelId);
        if (data?.data?.status_code === 200) {
          await LoadMoreTableData(loadOffset);
          message.success(data?.data?.message);
        }
        setLoading(false);
      }
      setIsModalOpen(false);
    } catch (error) {
      setLoading(false);
      setIsModalOpen(false);
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const cancelPaymentIntent = async (id) => {
    showModal();
    setCancelId(id);
  };

  const LoadMoreTableData = async (offsetValue) => {
    try {
      setLoading(true);
      setLoadOffset(offsetValue);
      let data = await getPaymentsListApi({
        offset: offsetValue,
        role: "client",
      });

      if (data?.data?.status_code === 200) {
        let tableData;
        tableData =
          data?.data?.data?.results?.length > 0
            ? data?.data?.data?.results?.map((item, index) => {
                return {
                  key: index,
                  game_title: item?.game?.title && (
                    <Link
                      onClick={() => {
                        navigate(`/game/${item?.game?.id}`);
                      }}
                    >
                      {item?.game?.title}
                    </Link>
                  ),
                  gm_email: item?.game?.game_master?.user && (
                    <Link
                      onClick={() => {
                        navigate(`/game-master/${item?.game?.game_master?.id}`);
                      }}
                    >
                      {item?.game?.game_master?.user}
                    </Link>
                  ),
                  client: item?.client || "",
                  status: item?.status || "",
                  session_date: timeConvertToDateTime(item.game?.date) || "",
                  action: item?.status === "pending" && (
                    <Link
                      style={{}}
                      onClick={() => {
                        cancelPaymentIntent(item?.id);
                      }}
                    >
                      <CloseCircleOutlined />
                    </Link>
                  ),
                };
              })
            : [];
        setCount(data?.data?.data?.count);
        setTableInfo(tableData);
        if (data?.data?.data?.count > offset + 10) {
          setOffset(offset + 10);
        }
      }
      setLoading(false);
    } catch (error) {
      // console.log(error)
      setLoading(false);
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    LoadMoreTableData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin spinning={loading}>
      <div className="profile-settings">
        <MenuSettings
          titleTextInfo={{
            title: "Bookings",
            text: "Details about your booked game sessions",
          }}
        />
        <div className="forms-wrapper payouts-wrap balance-wrap">
          {tableInfo && count > 0 && (
            <Table
              className="balance-table"
              dataSource={tableInfo || []}
              columns={columns}
              pagination={{
                defaultCurrent: 1,
                pageSize: 10,
                total: count,
                onChange: (data) => {
                  LoadMoreTableData((data - 1) * 10);
                },
              }}
            />
          )}
          <Modal
            title={"Are you sure?"}
            className="payout-modal"
            okText="Cancel Booking"
            width={400}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              By clicking 'Cancel Booking', I understand that my booking will be
              canceled immediately.
            </p>
          </Modal>
        </div>
        {count === 0 && (
          <Title className="secondary-heading text-center" level={4}>
            There is no data to show
          </Title>
        )}
      </div>
    </Spin>
  );
};

export default MyPaymentsPage;

import React from "react";
import GameSessionsMain from "../components/GameSessions/GameSessions";

const GameSessions = () => {
  return (
    <>
      <GameSessionsMain />
    </>
  );
};

export default GameSessions;

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, DatePicker, Spin, Row, Col } from "antd";

import TextEditor from "./textEditor";

import dayjs from "dayjs";
import {
  gameListApi,
  countriesApi,
  lauguagesApi,
  timeZonesApi,
  getMeApi,
} from "../../network/api/otherDetailsApi";
import { useSelector } from "react-redux";
import { updateUserProApi } from "../../network/api/authApi";

import { useNavigate } from "react-router-dom";
import MenuSettings from "./settingsMenu";

const dateFormat = "YYYY-MM-DD";

const PersonalProfile = () => {
  const [fileList, setFileList] = useState("");
  const [fileListCov, setFileListCov] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // const [loading1, setLoading1] = useState(false);
  let { gameList, countries, username, timezons, languages } = useSelector(
    (state) => ({
      gameList: state.otherDetails.gamesList,
      countries: state.otherDetails.countries,
      timezons: state.otherDetails.timezons,
      username: state.authUser.user.user_name,
      languages: state.otherDetails.languages,
    })
  );

  const onFinish = async (values) => {
    try {
      let reqbody = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        about_me: values?.about_me,
        country: values?.country,
        language: values?.language,
        time_zone: values?.time_zone,
        birthday: values.birthday.format("YYYY-MM-DD"),
        fav_game: values?.fav_game,
      };
      let data = await updateUserProApi(reqbody);
      if (data) {
        navigate("/settings");
      }
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const ListApiCalls = async () => {
    try {
      setLoading(true);
      let [, , , , preUserData] = await Promise.all([
        gameListApi(),
        countriesApi(),
        lauguagesApi(),
        timeZonesApi(),
        getMeApi(),
      ]);
      preUserData &&
        form.setFieldsValue({
          first_name: preUserData?.data?.data?.first_name,
          last_name: preUserData?.data?.data?.last_name,
          about_me: preUserData?.data?.data?.about_me,
          country: preUserData?.data?.data?.country,
          time_zone: preUserData?.data?.data?.time_zone,
          fav_game: preUserData?.data?.data?.fav_game,
          language: preUserData?.data?.data?.language,
          birthday: preUserData?.data?.data?.birthday
            ? dayjs(preUserData?.data?.data?.birthday, dateFormat)
            : null,
        });

      if (preUserData?.data?.data?.picture) {
        preUserData && setFileList(preUserData?.data?.data?.picture);
      }
      if (preUserData?.data?.data?.poster) {
        preUserData && setFileListCov(preUserData?.data?.data?.poster);
      }
      setLoading(false);
    } catch (error) {
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    ListApiCalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Spin spinning={loading} size="large">
      <div className="profile-head"></div>
      <div className="profile-settings">
        <MenuSettings
          titleTextInfo={{
            title: "Personal Profile",
            text: "Update information with ease",
          }}
          profile={true}
          fileList={fileList}
          setFileList={setFileList}
          fileListCov={fileListCov}
          setFileListCov={setFileListCov}
        />
        <div className="container forms-wrap">
          <Form
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            className="startgames-forms"
          >
            <div className="forms-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                      {
                        pattern: /[^ \s]/,
                        message: "Enter a valid name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your First Name" size="large" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                      {
                        pattern: /[^ \s]/,
                        message: "Enter a valid name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Last Name" size="large" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="About"
                name="about_me"
                rules={[
                  {
                    required: true,
                    message: "Please input your About!",
                  },
                  {
                    pattern: /[^ \s]/,
                    message: "Enter a valid text",
                  },
                ]}
              >
                <TextEditor />
              </Form.Item>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: "Please select your Country",
                      },
                    ]}
                  >
                    <Select
                      value={countries[0]?.id}
                      size="large"
                      showSearch
                      optionFilterProp="children"
                    >
                      {!!countries.length > 0 &&
                        countries.map((countrydata) => (
                          <Select.Option
                            value={countrydata.id}
                            key={countrydata?.id}
                          >
                            {countrydata?.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Time Zone"
                    name="time_zone"
                    rules={[
                      {
                        required: true,
                        message: "Please select your Time Zone",
                      },
                    ]}
                  >
                    <Select
                      value={timezons[0]?.id}
                      size="large"
                      showSearch
                      optionFilterProp="children"
                    >
                      {!!timezons.length > 0 &&
                        timezons.map((timeZone) => (
                          <Select.Option value={timeZone[0]} key={timeZone[0]}>
                            {timeZone[1]}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Date of Birth"
                name="birthday"
                rules={[
                  {
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <DatePicker size="large" format={dateFormat} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Favourite Games"
                    name="fav_game"
                    rules={[
                      {
                        required: true,
                        message: "Please select your favourite games",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      size="large"
                      placeholder="Please select"
                      options={gameList.map((game) => {
                        return { label: game.name, value: game.id };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                      {
                        required: true,
                        message: "Please select your language",
                      },
                    ]}
                  >
                    <Select
                      value={countries[0]?.id}
                      size="large"
                      showSearch
                      optionFilterProp="children"
                    >
                      {languages.length > 0 &&
                        languages.map((language) => (
                          <Select.Option value={language.id} key={language?.id}>
                            {language?.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item label="Username" name="username">
                <Input
                  disabled
                  placeholder={username}
                  value={username}
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="submit-btn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Save Changes
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default PersonalProfile;

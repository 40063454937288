import React from "react";
import GamerMasterProfile from "../components/Settings/gameMasterProfile";
import MainLayout from "../layout/mainLayout";

const MasterProfile = () => {
  return (
    <MainLayout>
      <GamerMasterProfile />
    </MainLayout>
  );
};

export default MasterProfile;

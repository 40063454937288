import React from "react";
import SinglePage from "../components/singlePage";

import MainLayout from "../layout/mainLayout";

const SingleGame = () => {
  return (
    <MainLayout>
      <SinglePage />
    </MainLayout>
  );
};

export default SingleGame;

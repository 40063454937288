import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Upload,
  Select,
  Checkbox,
  DatePicker,
  Spin,
  Row,
  Col,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { createGameApi, gameImageApi } from "../../network/api/otherDetailsApi";
import { useNavigate } from "react-router-dom";
import MenuSettings from "../Settings/settingsMenu";
import TextEditor from "../Settings/textEditor";
import { localToUtc, timeZone } from "../../utils/utils";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const CreateGameForm = () => {
  const navigate = useNavigate();
  const [imgfile, setImgFile] = useState([]);
  const [loading, setLoading] = useState(false);

  const [fileType, setFileType] = useState("");
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    debugger;
    try {
      setLoading(true);
      let image;
      let imagePath;
      if (imgfile?.length > 0) {
        let formData = new FormData();
        formData.append("file", imgfile[0]);
        formData.append("content_type", fileType);
        image = await gameImageApi(formData);
      }
      if (image?.status === 200) {
        imagePath = image?.data?.data?.file_url;
      }
      let data = await createGameApi({
        title: values?.title,
        image: imagePath || "",
        description: values?.description,
        cost: values?.cost,
        date: localToUtc(values?.date),
        duration: values?.duration,
        seats: values?.seats,
        type: values?.type,
        required_experience: values?.required_experience,
        character_creation: values?.character_creation,
        age_restricted: values?.mark_check || false,
        player_needed: values?.player_needed,
      });
      if (data?.status === 200) {
        setLoading(false);
        navigate("/gm-games");
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      if (error?.message === "Unauthorized") {
        navigate("/login");
      }
    }
  };

  const onChange = async (e) => {
    let file = e?.fileList[0]?.originFileObj;
    let type = e?.file?.type;
    setFileType(type);
    setImgFile([file]);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt4M = file.size / 1024 / 1024 < 4;
    if (!isLt4M) {
      message.error("Image must smaller than 4MB!");
    }

    if (isJpgOrPng && isLt4M) {
      return false;
    }

    return Upload.LIST_IGNORE;
  };

  return (
    <Spin spinning={loading} size="large">
      <div className="profile-settings">
        <MenuSettings
          titleTextInfo={{
            title: "Create Game",
          }}
        />
        <div className="container forms-wrap">
          <Form
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            className="startgames-forms"
          >
            <div className="forms-wrapper">
              <Form.Item
                label={"Game Picture"}
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please Upload Game Thumbnail!",
                  },
                ]}
              >
                <Upload
                  listType="picture"
                  multiple={false}
                  onChange={onChange}
                  fileList={imgfile}
                  className="upload-game-profile"
                  beforeUpload={beforeUpload}
                >
                  <Button block ghost size="large" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                label={"Game Title"}
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Game Title!",
                  },
                  {
                    pattern: /[^ \s]/,
                    message: "Enter a valid title",
                  },
                ]}
              >
                <Input placeholder="Enter your game title" size="large" />
              </Form.Item>
              <Form.Item
                label="Game Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input your Game Description!",
                  },
                  {
                    pattern: /[^ \s]/,
                    message: "Enter a valid Description",
                  },
                ]}
              >
                <TextEditor />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label="Cost Per Session €"
                    name="cost"
                    rules={[
                      {
                        required: true,
                        message: "Please input cost per session!",
                      },
                    ]}
                    initialValue={1}
                  >
                    <InputNumber
                      size="large"
                      placeholder="cost per session €"
                      className="counter-field"
                      min={1}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label={`Select Date Time (${timeZone()})`}
                    name="date"
                    rules={[
                      {
                        required: true,
                        message: "Please input session date and time",
                      },
                    ]}
                  >
                    <DatePicker
                      size="large"
                      format={dateFormat}
                      placeholder="session date time"
                      showTime
                      disabledDate={(current) => {
                        return current && current.valueOf() < Date.now();
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label="Total Seats"
                    name="seats"
                    rules={[
                      {
                        required: true,
                        message: "Please input total seats for the session",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="counter-field"
                      placeholder="total seats"
                      min={1}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label="Duration (hrs)"
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: "Please input session duration",
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="duration in hours"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label="Game Type"
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select game type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Game Type"
                      value={"one_shot"}
                      showSearch
                      optionFilterProp="children"
                      size="large"
                    >
                      <Select.Option value="one_shot">One Shot</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}>
                  <Form.Item
                    label="Required Experience"
                    name="required_experience"
                    rules={[
                      {
                        required: true,
                        message: "Please select required experience",
                      },
                    ]}
                  >
                    <Select size="large" showSearch optionFilterProp="children">
                      <Select.Option value="not_required">
                        Not required
                      </Select.Option>
                      <Select.Option value="beginner">Beginner</Select.Option>
                      <Select.Option value="experienced">
                        Experienced
                      </Select.Option>
                      <Select.Option value="expert">Expert</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Character Creation"
                    name="character_creation"
                    rules={[
                      {
                        required: true,
                        message: "Please select character creation",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      optionFilterProp="children"
                      value={"created_in_game"}
                    >
                      <Select.Option value="created_in_game">
                        Created In Game
                      </Select.Option>
                      <Select.Option value="created_by_players_beforehand">
                        Created By Players Beforehad
                      </Select.Option>
                      <Select.Option value="created_by_game_master_beforehand">
                        Created By Game Master Beforehand
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label="What do players need before the game session?"
                name="player_needed"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input what do players need before the game?",
                  },
                  {
                    pattern: /[^ \s]/,
                    message: "Enter a valid Description",
                  },
                ]}
              >
                <TextEditor />
              </Form.Item>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="mark_check"
                    className="age-restriction"
                    valuePropName="checked"
                  >
                    <Checkbox value={false}>Age Restricted</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  className="submit-btn"
                  type="primary"
                  size="large"
                  htmlType="submit"
                >
                  Create Game
                </Button>
              </Form.Item> 
            </div>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default CreateGameForm;

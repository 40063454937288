import React from "react";
import FindALLGames from "../components/FindGames";
import MainLayout from "../layout/mainLayout";

const FindGames = () => {
  return (
    <MainLayout>
      <FindALLGames />
    </MainLayout>
  );
};

export default FindGames;

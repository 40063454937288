import React from "react";

const GameSessionsMain = () => {
  return (
    <>
      <h1>asdasd</h1>
    </>
  );
};

export default GameSessionsMain;

import { Col, Row, Typography, Divider, Space, Image } from "antd";
import React from "react";

import { Link } from "react-router-dom";

const { Title } = Typography;
export default function Footer() {
  return (
    <div className="default-footer">
      <div className="container">
        <Divider className="seprator" />
        <Row gutter={15}>
          =
          <Col xs={12} sm={8} md={5}>
            <Title level={5}>Browse</Title>
            <ul>
              <li>
                <Link to="/games">Games</Link>
              </li>
              <li>
                <Link to="/game-masters">GMs</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={8} md={4}>
            <Title level={5}>Policy</Title>
            <ul>
              <li>
                <a href="#About">About</a>
              </li>
              <li>
                <a href="#Blog">Blog</a>
              </li>
              <li>
                <a href="#Contact Us">Contact Us</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={8} md={4}>
            {" "}
            <Title level={5}>Support</Title>
            <ul>
              <li>
                <a href="#Contact">Contact support</a>
              </li>
              <li>
                <a href="#Report">Report</a>
              </li>
              <li>
                <a href="#FaQ">FaQ</a>
              </li>
            </ul>
          </Col>
          <Col className="text-left" xs={12} sm={16} md={6}>
            <Title level={5}>Your Account</Title>
            <ul>
              <li>
                <a href="#Support">Support</a>
              </li>
            </ul>
            <Title className="follow-us" level={5}>
              Follow Us
            </Title>
            <Space>
              <a href="#twitter">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/twitter.png"}
                />
              </a>
              <a href="#facebook">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/facebook.png"}
                />
              </a>
              <a href="#instagram">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/instagram.png"}
                />
              </a>
              <a href="#linkedin">
                <Image
                  width={40}
                  height={40}
                  preview={false}
                  src={"/linkedin.png"}
                />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Typography, Menu, Upload, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ImgCrop from "antd-img-crop";

import {
  SettingOutlined,
  FireOutlined,
  CameraFilled,
  LoadingOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import {
  coverImageApi,
  profileImageApi,
} from "../../network/api/otherDetailsApi";
import { setPosterPic, setProfilePic } from "../../store/slices/authUser";

const { Title, Text } = Typography;

const MenuSettings = ({
  titleTextInfo,
  profile = false,
  fileList,
  setFileList,
  fileListCov,
  setFileListCov,
}) => {
  const [selectKey, setSelectKey] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const role = useSelector((state) => state.authUser.user.role_type);
  const ImageProfile = useSelector((state) => state.authUser.user.picture);
  const ImageCover = useSelector((state) => state.authUser.user.poster);
  // const currentRoute = useLocation();

  const handleModalOk = async (file) => {
    if (!["image/jpeg", "image/jpg", "image/png"].includes(file?.type)) {
      setLoading1(false);
      message.error(
        "The profile picture must be a file of type: png, jpg, jpeg"
      );
    } else {
      try {
        setLoading1(true);
        let type = file?.type;
        if (file) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("content_type", type);
          formData.append("image_type", "profile_picture");

          let data = await profileImageApi(formData);
          if (data?.data?.status_code === 200) {
            setFileList(data?.data?.data);
            dispatch(setProfilePic(data?.data?.data || ""));
          }
        }
        setLoading1(false);
      } catch (error) {
        setLoading1(false);
        if (error?.message === "Unauthorized") {
          navigate("/login");
        }
      }
    }
  };

  const handleCoverModalOk = async (file) => {
    debugger;
    if (!["image/jpeg", "image/jpg", "image/png"].includes(file?.type)) {
      setLoading1(false);
      message.error(
        "The profile picture must be a file of type: png, jpg, jpeg"
      );
    } else {
      try {
        setLoading2(true);
        let type = file?.type;
        if (file) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("content_type", type);

          let data = await coverImageApi(formData);

          if (data?.data?.status_code === 200) {
            setFileListCov(data?.data?.data);
            dispatch(setPosterPic(data?.data?.data || ""));
          }
        }
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        if (error?.message === "Unauthorized") {
          navigate("/login");
        }
      }
    }
  };

  useEffect(() => {
    const currentPathname = window.location.pathname;
    if (currentPathname === "/dashboard") {
      setSelectKey("dashboard");
    } else if (currentPathname === "/games") {
      setSelectKey("my-game");
    } else {
      setSelectKey("settings");
    }
  }, []);

  function hasGameMasterRole(arr) {
    if (!Array.isArray(arr)) return false;
    return arr.some((item) => item?.role === "game_master");
  }

  const menuItems = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: "Dashboard",
      onClick: () => {
        navigate("/dashboard");
        setSelectKey("dashboard");
      },
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: "Settings",
      onClick: () => {
        navigate("/settings");
        setSelectKey("settings");
      },
    },
  ];

  if (role?.length > 0 && Array.isArray(role) && hasGameMasterRole(role)) {
    menuItems.push({
      key: "my-game",
      icon: <FireOutlined />,
      label: "My Games",
      onClick: () => {
        navigate("/gm-games");
        setSelectKey("my-game");
      },
    });
  }

  // Uncomment the following line if you decide to add the "Post a game" option again
  // menuItems.push({
  //   key: "post-game",
  //   icon: <FireOutlined />,
  //   label: "Post a game",
  //   onClick: () => {
  //     navigate("/gm-games/create-game");
  //     setSelectKey("post-game");
  //   },
  // });

  return (
    <>
      <Menu
        mode="horizontal"
        className="nav-menu"
        defaultSelectedKeys={["settings"]}
        selectedKeys={selectKey}
        items={menuItems}
      />

      <div className="container mx-900">
        <div className="page-header">
          {profile && (
            <div className="cover-upload-btn">
              <ImgCrop
                aspectSlider
                rotationSlider
                onModalOk={handleCoverModalOk}
              >
                <Upload
                  fileList={[]}
                  // onChange={onChangeCov}
                  multiple={false}
                  accept={".png,.jpeg,.jpg"}
                  beforeUpload={(e) => {
                    return e;
                  }}
                >
                  <Button
                    circle
                    icon={loading2 ? <LoadingOutlined /> : <CameraFilled />}
                  ></Button>
                </Upload>
              </ImgCrop>
            </div>
          )}
          {(fileListCov || ImageCover) && (
            <img
              className="setting-cover"
              src={fileListCov || ImageCover}
              alt="cover"
            />
          )}
          <div className="setting-content">
            <Title level={2}>{titleTextInfo?.title}</Title>
            <Text className="profile-text">{titleTextInfo?.text}</Text>
          </div>
          {profile ? (
            <div className="profile-thumbnail">
              <ImgCrop aspectSlider rotationSlider onModalOk={handleModalOk}>
                <Upload
                  // listType="picture-card"
                  fileList={[]}
                  // onChange={onChange}
                  multiple={false}
                  accept={".png,.jpeg,.jpg"}
                  // onPreview={onPreview}
                  beforeUpload={(e) => {
                    return e;
                  }}
                >
                  <Button
                    className="upload-iconbtn"
                    circle
                    icon={loading1 ? <LoadingOutlined /> : <CameraFilled />}
                  ></Button>
                </Upload>
              </ImgCrop>
              {(fileList || ImageProfile) && (
                <img
                  src={fileList || ImageProfile}
                  preview={false}
                  alt="profile"
                />
              )}
            </div>
          ) : (
            <div className="profile-thumbnail">
              {(fileList || ImageProfile) && (
                <img
                  src={fileList || ImageProfile}
                  preview={false}
                  alt="profile"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MenuSettings;

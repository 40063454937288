import React from "react";
import PersonalProfile from "../components/Settings/personalProfile";
import MainLayout from "../layout/mainLayout";

const Home = () => {
  return (
    <MainLayout>
      <PersonalProfile />
    </MainLayout>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Space,
  Form,
  Button,
  Select,
  Spin,
  Row,
  Col,
  Pagination,
  Slider,
  DatePicker,
} from "antd";
import {
  getFindGamesPublicApi,
  maxCostDurApi,
} from "../../network/api/otherDetailsApi";
import GameComp from "../HomePage/gameComp";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const FindALLGames = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const [durationRange, setdurationRange] = useState([0, 0]);
  const [costRange, setCostRange] = useState([0, 0]);
  const [experience, setExperience] = useState("");
  const [maxDuration, setMaxDuration] = useState(0);
  const [maxCost, setMaxCost] = useState(0);

  const [apply, setApply] = useState(false);

  const [gameList, setGameList] = useState([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);

  const DurationSliderFor = (value) => {
    return `${value} hours`;
  };
  const CostSliderFor = (value) => {
    return `${value} eur`;
  };

  const getMaxValues = async () => {
    try {
      setLoading(true);
      let data = await maxCostDurApi();
      if (data?.status_code === 200) {
        setMaxCost(data?.data?.max_cost);
        setMaxDuration(data?.data?.max_duration);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getGames = async () => {
    try {
      setLoading(true);
      let games = await getFindGamesPublicApi({
        offset: offset,
        durationRange,
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        costRange,
        experience,
      });
      if (games?.status_code === 200) {
        setGameList(games?.data?.results || []);
        setCount(games?.data?.count);
        if (games?.data?.count > offset + 20) {
          setOffset(offset + 20);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMaxValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apply]);
  return (
    <>
      <div className="games-wrapper">
        <div className="sub-banner">
          <div className="container">
            <Title level={2}>Find Games</Title>
            <Text className="profile-text">
              Play D&D and Other Tabletop Roleplaying Games Online
            </Text>
          </div>
        </div>
        <div className="filter-wrap">
          <Spin spinning={loading} size="large">
            <Form layout="vertical">
              <Space wrap className="filter-inr" size={16}>
                <Space className="filter-form-wrap" wrap size={30}>
                  <Form.Item label="Duration">
                    <Slider
                      max={maxDuration}
                      range
                      style={{ width: "9.375rem" }}
                      tooltip={{
                        open: true,
                        placement: "bottom",
                        formatter: DurationSliderFor,
                        getPopupContainer: (triggerNode) =>
                          triggerNode.parentElement,
                      }}
                      value={durationRange}
                      onChange={(value) => {
                        setdurationRange(value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Date">
                    <DatePicker
                      value={date}
                      ghost
                      size="large"
                      onChange={(date) => {
                        setDate(date);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Cost">
                    <Slider
                      max={maxCost}
                      range
                      value={costRange}
                      onChange={(value) => {
                        setCostRange(value);
                      }}
                      tooltip={{
                        open: true,
                        placement: "bottom",
                        formatter: CostSliderFor,
                        getPopupContainer: (triggerNode) =>
                          triggerNode.parentElement,
                      }}
                      style={{ width: "9.375rem" }}
                    />
                  </Form.Item>
                  <Form.Item label="Required Experienced">
                    <Select
                      value={experience}
                      onChange={(value) => {
                        setExperience(value);
                      }}
                      size="large"
                      style={{
                        width: "11.25rem",
                      }}
                      options={[
                        {
                          value: "not_required",
                          label: "Not Required",
                        },
                        {
                          value: "beginner",
                          label: " Beginner",
                        },
                        {
                          value: "experienced",
                          label: "Experienced",
                        },
                        {
                          value: "expert",
                          label: "Expert",
                        },
                      ]}
                    />
                  </Form.Item>
                </Space>
                <Space className="apply-filters">
                  <Button
                    size="large"
                    className="clear-filter"
                    ghost
                    onClick={() => {
                      setApply(!apply);
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    size="large"
                    className="clear-filter"
                    ghost
                    onClick={() => {
                      setCostRange([0, 0]);
                      setDate("");
                      setdurationRange([0, 0]);
                      setExperience("");
                      setApply(!apply);
                    }}
                  >
                    Clear Filter
                  </Button>
                </Space>
              </Space>
            </Form>
            <div className="container">
              <Row style={{ marginTop: 30 }} gutter={[16, 16]}>
                {gameList?.length > 0 &&
                  gameList?.map((game, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                          <GameComp game={game} />
                        </Col>
                      </React.Fragment>
                    );
                  })}
              </Row>

              {count > 0 && (
                <div style={{ marginTop: "100px" }}>
                  <Pagination
                    align="center"
                    defaultCurrent={1}
                    pageSize={20}
                    showSizeChanger={false}
                    onChange={getGames}
                    total={count}
                  />
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </>
  );
};

export default FindALLGames;

import React from "react";
import DeleteAccount from "../components/Settings/deleteAccount";
import MainLayout from "../layout/mainLayout";

const Delete = () => {
  return (
    <MainLayout>
      <DeleteAccount />
    </MainLayout>
  );
};

export default Delete;

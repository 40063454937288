import React from "react";
import HomePage from "../components/HomePage";
import MainLayout from "../layout/mainLayout";

const Home = () => {
  return (
    <MainLayout>
      <HomePage />
    </MainLayout>
  );
};

export default Home;

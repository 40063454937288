import React from "react";
import CreateGameForm from "../components/CreateGame";
import MainLayout from "../layout/mainLayout";

const CreateGame = () => {
  return (
    <MainLayout>
      <CreateGameForm />
    </MainLayout>
  );
};

export default CreateGame;

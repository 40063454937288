import React from "react";
import { Typography, Space, Tag, Image } from "antd";
import { useNavigate } from "react-router-dom";
import { convert } from "html-to-text";

const { Title, Text, Paragraph } = Typography;

export default function BlogComp({ blog }) {
  const navigate = useNavigate();
  return (
    <div
      className="blog-card"
      onClick={() => {
        navigate(`/blogs/${blog?.id}`);
      }}
    >
      <Image src={blog?.image || "/blog-img.jpg"} preview={false} />
      <div className="blog-content-inr">
        <Space className="game-tags">
          Tags:
          {blog?.tags?.length > 0 &&
            blog?.tags?.map((tag) => {
              return (
                <React.Fragment key={`blog-${blog.id}`}>
                  <Tag color="default">{tag?.title}</Tag>
                </React.Fragment>
              );
            })}
        </Space>
        <Title level={3} style={{ color: "white" }}>
          {blog?.title}{" "}
        </Title>
        <Paragraph className="description">
          {convert(blog?.description, {
            wordwrap: 130,
          }).substring(0, 780) + "..."}
        </Paragraph>
        <Text className="date-create">
          <span>Created Date:</span> {blog?.created_at}
        </Text>
      </div>
    </div>
  );
}
